import React from 'react';

import { Route, BrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import Detail from './pages/Detail';
import List from './pages/List';

const Routes = () => {
  return (
    <BrowserRouter basename="/app">
      <Route component={Home} path='/' exact />
      <Route component={Detail} path='/detail/:id' exact />
      <Route component={Detail} path='/detail/:id/:usuario' />
      <Route component={List} path='/list/:usuario' />
    </BrowserRouter>
  );
}


export default Routes;