import React, { useState, useEffect, FormEvent, ChangeEvent, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMap } from 'react-icons/fi';
import { Map, TileLayer, Marker, Viewport } from 'react-leaflet';

import { RouteComponentProps } from 'react-router-dom';

import './styles.css';

import logo from '../../assets/sol-internet-siempre-online.jpg';
import api from '../../services/api';


interface ListRouteComponentProps extends RouteComponentProps<{
  usuario: string;
}> { }


interface Asistencia {
  id: string,
  codPlan: string,
  plan: string,
  cliente: string,
  mensaje: string
}

interface AsistenciaResponse {
  data: Asistencia[]
}

interface ServerError {
  error: number,
  message: string
}

const List: React.FC<ListRouteComponentProps> = (props) => {

  const usuario = props.match.params.usuario;
  const [errorMessage, setErrorMessage] = useState<string>();

  const [asistencias, setAsistencias] = useState<Asistencia[]>();


  useEffect(() => {
    api
      .get<AsistenciaResponse>(`asistencias-api/get-asistencias/${usuario}`)
      .then(response => {

        setAsistencias(response.data.data);

      })
      .catch(err => {

        const errData = err.response.data as ServerError;

        setErrorMessage(errData.message ? errData.message : err.message);
      });

  }, []);

  //https://solweb.sol.com.py/asistencias-api/get-asistencias/20

  if (errorMessage) {
    return (
      <div id="page-create-point">
        <header>
          <img src={logo} alt="Sol Asistencias" />

          <Link to="/">
            <FiArrowLeft></FiArrowLeft>
            Volver al inicio
          </Link>
        </header>

        <form>
          <fieldset>
            <div className="field">
              <label className="error-message">ERROR: {errorMessage}</label>
            </div>
          </fieldset>
        </form>

      </div>
    );
  }


  return (
    <div id="page-create-point">
      <header>
        <img src={logo} alt="Sol Asistencias" />

        <Link to="/">
          <FiArrowLeft></FiArrowLeft>
          Volver al inicio
        </Link>
      </header>

      <div className="main">
        <h1>Órdenes de servicio de {usuario}</h1>

        <ul>

          {asistencias && asistencias.length && asistencias.map(asistencia => (
            <li key={asistencia.id}>
              <h2><em>Asistencia nº:</em> {asistencia.id}</h2>
              <p><em>Cliente: </em>{asistencia.cliente}</p>
              <p><em>Plan: </em>{asistencia.plan}</p>
              <p><em>Motivo: </em>{asistencia.mensaje}</p>
              <Link className="button" to={`/detail/${asistencia.codPlan}/${usuario}`}>
                <strong>Ver detalles del plan</strong>
              </Link>
            </li>
          ))}

        </ul>

      </div>

    </div>

  );
};

export default List;