import React, { useState, useEffect, FormEvent, ChangeEvent, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiMap } from 'react-icons/fi';
import { Map, TileLayer, Marker, Viewport } from 'react-leaflet';

import { RouteComponentProps } from 'react-router-dom';

import './styles.css';

import logo from '../../assets/sol-internet-siempre-online.jpg';
import api from '../../services/api';


interface DetailRouteComponentProps extends RouteComponentProps<{
  id: string,
  usuario?: string
}> { }


interface Plan {
  id: string,
  idPlan: string,
  descripcion: string,
  referencia: string,
  idCliente: string,
  nombreCliente: string,
  status: string,
  statusName: string,
  download: string,
  upload: string,
  valor: string,
  valorUSD: string,
  tipoCliente: string,
  tipoClienteName: string,
  sucursal: string,
  ciudad: string,
  barrio: string,
  ubicacion: string,
  latitud: number,
  longitud: number
}

interface ServerError {
  error: number,
  message: string
}

const Detail: React.FC<DetailRouteComponentProps> = (props) => {

  const history = useHistory();
  const codPlan = props.match.params.id;
  const usuario = props.match.params.usuario;


  const [planCoords, setPlanCoords] = useState<[number, number]>([0, 0]);

  const initialViewPort: Viewport = {
    center: [-25.7953629, -55.0824197],
    zoom: 14
  };
  const [viewport, setViewPort] = useState<Viewport>(initialViewPort);



  const [plan, setPlan] = useState<Plan>();
  const [errorMessage, setErrorMessage] = useState<string>();


  const [formData, setFormData] = useState({
    codPlan: ''
  });



  useEffect(() => {
    api
      .get<Plan>(`asistencias-api/get-plan-details/${codPlan}`)
      .then(response => {

        const coords: [number, number] = [response.data.latitud, response.data.longitud];

        setPlan(response.data);
        setPlanCoords(coords);
        setViewPort({
          center: coords[0] != 0 ? coords : initialViewPort.center,
          zoom: initialViewPort.zoom
        });

      })
      .catch(err => {

        const errData = err.response.data as ServerError;

        setErrorMessage(errData.message ? errData.message : err.message);
      });

  }, []);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleCenterButtonClick() {
    const center = getPlanInitialPosition();

    setViewPort({
      center: center,
      zoom: initialViewPort.zoom
    });
  }

  function getPlanInitialPosition() {

    console.log("initial position get", planCoords);

    if (planCoords[0] != 0) {
      return planCoords;
    }

    return initialViewPort.center;

  }

  if (errorMessage) {
    //if (!plan) {
    return (
      <div id="page-create-point">
        <header>
          <img src={logo} alt="Sol Asistencias" />

          {!usuario && (
            <Link to="/">
              <FiArrowLeft></FiArrowLeft>
              Volver al inicio
          </Link>)}

          {usuario && (
            <Link to={`/list/${usuario}`}>
              <FiArrowLeft></FiArrowLeft>
              Volver a órdenes de servicio
          </Link>)}

        </header>

        <form>
          <fieldset>
            <div className="field">
              <label className="error-message">ERROR: {errorMessage}</label>
            </div>
          </fieldset>
        </form>

      </div>
    );
  }

  if (!plan) {
    return (
      <div id="page-create-point">
        <header>
          <img src={logo} alt="Sol Asistencias" />

          {!usuario && (
            <Link to="/">
              <FiArrowLeft></FiArrowLeft>
              Volver al inicio
          </Link>)}

          {usuario && (
            <Link to={`/list/${usuario}`}>
              <FiArrowLeft></FiArrowLeft>
              Volver a órdenes de servicio
          </Link>)}
        </header>

        <form>
          <p>Espera mientras buscamos el plan código: <b>{codPlan}</b>...</p>

        </form>

      </div>
    );
  }


  return (
    <div id="page-create-point">
      <header>
        <img src={logo} alt="Sol Asistencias" />

        {!usuario && (
          <Link to="/">
            <FiArrowLeft></FiArrowLeft>
            Volver al inicio
          </Link>)}

        {usuario && (
          <Link to={`/list/${usuario}`}>
            <FiArrowLeft></FiArrowLeft>
            Volver a órdenes de servicio
          </Link>)}
      </header>

      <form>
        <h1>Accediste al plan {codPlan}</h1>

        <fieldset>
          <legend>
            <h2>Datos del plan</h2>
          </legend>

          <div className="field">
            <label>Descripción</label>
            <input
              type="text"
              name="descripcion"
              value={plan.descripcion}
              disabled
            />
          </div>

          <div className="field">
            <label>Referencia</label>
            <input
              type="text"
              name="referencia"
              value={plan.referencia}
              disabled
            />
          </div>

          <div className="field">
            <label>Cliente</label>
            <input
              type="text"
              name="nombreCliente"
              value={plan.nombreCliente}
              disabled
            />
          </div>

          <div className="field">
            <label>Tipo</label>
            <input
              type="text"
              name="tipoClienteName"
              value={plan.tipoClienteName}
              disabled
            />
          </div>

          <div className="field">
            <label>Status</label>
            <input
              type="text"
              name="statusName"
              value={plan.statusName}
              disabled
            />
          </div>

          <div className="field">
            <label>Download</label>
            <input
              type="text"
              name="download"
              value={plan.download}
              disabled
            />
          </div>

          <div className="field">
            <label>Upload</label>
            <input
              type="text"
              name="upload"
              value={plan.upload}
              disabled
            />
          </div>

          <div className="field">
            <label>Valor</label>
            <input
              type="text"
              name="valorUSD"
              value={plan.valorUSD}
              disabled
            />
          </div>

        </fieldset>

        <fieldset>
          <legend>
            <h2>Ubicación</h2>
          </legend>

          <div className="field">
            <label>Sucursal</label>
            <input
              type="text"
              name="sucursal"
              value={plan.sucursal}
              disabled
            />
          </div>

          <div className="field">
            <label>Ciudad</label>
            <input
              type="text"
              name="ciudad"
              value={plan.ciudad}
              disabled
            />
          </div>

          <div className="field">
            <label>Barrio</label>
            <input
              type="text"
              name="barrio"
              value={plan.barrio}
              disabled
            />
          </div>

          <div className="field">
            <label>Ubicacion</label>
            <textarea name="ubicacion" value={plan.ubicacion} disabled></textarea>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <h2>GPS</h2>
          </legend>

          <div className="field">
            <label>Latitud</label>
            <input
              type="text"
              name="latitud"
              value={plan.latitud}
              disabled
            />
          </div>

          <div className="field">
            <label>Longitud</label>
            <input
              type="text"
              name="ciudad"
              value={plan.longitud}
              disabled
            />
          </div>

          {viewport.center && viewport.zoom && planCoords[0] != 0 && (<>


            <a className="button" target="_blank" href={`https://www.google.com/maps?q=${planCoords[0]},${planCoords[1]}&hl=es&gl=us&shorturl=1`}>
              <span>
                <FiMap />
              </span>
              <strong>Ver en Google Maps</strong>
            </a>

            <Map
              center={viewport.center}
              zoom={viewport.zoom}
              viewport={viewport}
              onViewportChanged={setViewPort}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <Marker position={planCoords} />
            </Map>

            <button className="secundary" type="button" onClick={handleCenterButtonClick}>Centralizar mapa</button>
          </>)
          }


        </fieldset>




      </form>

    </div>

  );
};

export default Detail;