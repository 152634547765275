import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';

import './styles.css';

import logo from '../../assets/sol-internet-siempre-online.jpg';


const Home = () => {

  const history = useHistory();

  const [formData, setFormData] = useState({
    codPlan: '',
    codUsuario: '',
  });

  const [errorMessage, setErrorMessage] = useState<String>();
  const [errorMessageUsuario, setErrorMessageUsuario] = useState<String>();

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }


  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if(!formData.codPlan) {
      setErrorMessage('Por favor, informe el código del plan.');
      return;
    }

    history.push(`/detail/${formData.codPlan}`);
  }

  function handleSubmitUsuario(event: FormEvent) {
    event.preventDefault();

    if(!formData.codUsuario) {
      setErrorMessageUsuario('Por favor, informe el ID del usuário.');
      return;
    }

    history.push(`/list/${formData.codUsuario}`);
  }


  return (
    <div id="page-create-point">
      <header>
        <img src={logo} alt="Ecoleta" />
      </header>

      <form onSubmit={handleSubmit}>
        <h1>Consulta ubicación del cliente.</h1>

        <fieldset>
          <legend>
            <h2>Datos</h2>
          </legend>

          <div className="field">
            <label htmlFor="codPlan">Código del plan</label>
            <input
              type="text"
              name="codPlan"
              id="codPlan"
              value={formData.codPlan}
              onChange={handleInputChange}
            />
            <label className="error-message">{errorMessage}</label>
          </div>

        </fieldset>

        <fieldset>
          <div className="field-group">
            <div className="field">
              <button type="reset">Limpiar</button>
            </div>
            <div className="field">
              <button type="submit">Ver detalles del plan</button>
            </div>
          </div>
        </fieldset>
      </form>

      <form onSubmit={handleSubmitUsuario}>
        <h1>Consulta órdenes de servicio.</h1>

        <fieldset>
          <legend>
            <h2>Datos</h2>
          </legend>

          <div className="field">
            <label htmlFor="codUsuario">ID del usuario</label>
            <input
              type="text"
              name="codUsuario"
              id="codUsuario"
              value={formData.codUsuario}
              onChange={handleInputChange}
            />
            <label className="error-message">{errorMessageUsuario}</label>
          </div>

        </fieldset>

        <fieldset>
          <div className="field-group">
            <div className="field">
              <button type="reset">Limpiar</button>
            </div>
            <div className="field">
              <button type="submit">Ver órdenes del usuario</button>
            </div>
          </div>
        </fieldset>
      </form>

    </div>

  );
};

export default Home;